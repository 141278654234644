<template>
  <el-upload
    v-loading="loading"
    :action="qiniu.actionPath"
    :data="qiniu.postData"
    class="upload-one-component"
    :style="{width: width,height: height}"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-success="uploadSuccess"
    :on-error="uploadError"
  >
    <img v-if="url && videoType.indexOf(url.substring(url.lastIndexOf('.')+1)) == -1" :src="url" class="upload-one-component-image" />
    <video v-if="url && videoType.indexOf(url.substring(url.lastIndexOf('.')+1)) != -1" :src="url" class="upload-one-component-image" autoplay='true' controls="true" ref="video" @canplay="getDuration"></video>
    <i v-if="!url" class="el-icon-plus upload-one-component-icon"></i>
  </el-upload>
</template>
<script>
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";

export default {
  name: "UploadOne",
  props: {
    url: {
      value: ""
    },
    width: {
      type: String,
      default: "120px"
    },
    height: {
      type: String,
      default: "120px"
    }
  },
  model: {
    prop: "url", //绑定的值，通过父组件传递
    event: "update" //自定义时间名
  },
  data() {
    return {
      loading: false,
      qiniu: {
        actionPath: "",
        baseUrl: "",
        postData: {}
      },
      videoType:['mp4','rmvb','wmv','avi','mov','flv'],
    };
  },
  created() {
    getQiniuToken().then(response => {
      // token可多次使用
      this.qiniu.actionPath = response.data.uploadUrl;
      this.qiniu.baseUrl = response.data.baseUrl;
      if (!this.qiniu.postData.token) {
        this.qiniu.postData.token = response.data.token;
      }
    });
  },
  methods: {
    beforeUpload(file) {
      
      // 上传前需生成图片的GUID唯一名
      this.qiniu.postData.key = guid() + '.' + file.type.split('/')[1];
      this.loading = true;
    },
    uploadSuccess(res) {
      
      let url = this.qiniu.baseUrl + "/" + res.key;
      this.$emit("update", url); //子组件与父组件通讯，告知父组件更新绑定的值
      this.loading = false;
    },
    uploadError(res) {
      this.loading = false;
      this.$message({
        type: "error",
        message: `操作失败,${res}`
      });
    },
    getDuration(){
      let duration = this.$refs.video.duration //获取视频的总时长
      this.$emit('getDuration',duration)
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
  .upload-one-component .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .upload-one-component-icon {
      font-size: 30px;
      color: #8c939d;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .upload-one-component-image {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .upload-one-component .el-upload:hover {
    border-color: #409eff;
  }
</style>
