import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/error/401",
    component: () => import("@/views/error/401"),
    hidden: true,
  },
  {
    path: "/error/404",
    component: () => import("@/views/error/404"),
    hidden: true,
  },
  {
    path: "",
    component: Layout,
    name: "Index",
    hidden: true,
    children: [
      // {
      //   path: "/",
      //   name: "Dashboard",
      //   meta: { title: "首页", icon: "dashboard", affix: true },
      //   component: () => import("@/views/dashboard/index"),
      // },
    ],
  },
  {
    path: "",
    component: Layout,
    name: "Info",
    hidden: true,
    children: [
      {
        path: "/info",
        component: () => import("@/views/info/index"),
      },
    ],
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: "history",
  routes: constantRouterMap,
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "user",
    meta: { title: "用户管理", permissions: ["admin", "user"], icon: "users" },
    children: [
      {
        path: "/user/list",
        name: "users",
        component: () => import("@/views/user/index"),
        meta: { title: "用户列表", permissions: ["admin", "user"] },
      },
      {
        path: "/user/vip_list",
        name: "vip_list",
        component: () => import("@/views/user/vip_list"),
        meta: { title: "会员列表", permissions: ["admin", "user"] },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "forum",
    meta: { title: "论坛管理", permissions: ["admin"], icon: "users" },
    children: [
      {
        path: "/forum/forum_list",
        name: "forum_list",
        component: () => import("@/views/forum/forum_list"),
        meta: { title: "帖子列表", permissions: ["admin", "user"] },
      },
      {
        path: "/forum/forum_details",
        name: "forum_details",
        hidden:true,
        component: () => import("@/views/forum/forum_details"),
        meta: { title: "帖子详情", permissions: ["admin", "user"] },
      },
      {
        path: "/forum/forum_user",
        name: "forum_user",
        hidden:true,
        component: () => import("@/views/forum/forum_user"),
        meta: { title: "用户评论", permissions: ["admin", "user"] },
      },
      {
        path: "/forum/user_report",
        name: "user_report",
        component: () => import("@/views/forum/user_report"),
        meta: { title: "用户举报", permissions: ["admin", "user"] },
      },
      {
        path: "/forum/sensitive",
        name: "sensitive",
        component: () => import("@/views/forum/sensitive"),
        meta: { title: "敏感词设置", permissions: ["admin", "user"] },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "music",
    meta: { title: "曲谱管理", permissions: ["admin"], icon: "content" },
    children: [
      {
        path: "/music/music_list",
        name: "music_list",
        component: () => import("@/views/music/music_list"),
        meta: { title: "曲谱列表", permissions: ["admin"] },
      },
      {
        path: "/music/music_edit",
        name: "music_edit",
        hidden:true,
        component: () => import("@/views/music/music_edit"),
        meta: { title: "曲谱编辑", permissions: ["admin"] },
      },
      {
        path: "/music/pay_list",
        name: "pay_list",
        hidden:true,
        component: () => import("@/views/music/pay_list"),
        meta: { title: "购买人列表", permissions: ["admin"] },
      },
      {
        path: "/music/music_videolist",
        name: "music_videolist",
        hidden:true,
        component: () => import("@/views/music/music_videolist"),
        meta: { title: "视频列表", permissions: ["admin"] },
      },
      {
        path: "/music/Papua_list",
        name: "Papua_list",
        component: () => import("@/views/music/Papua_list"),
        meta: { title: "扒谱列表", permissions: ["admin"] },
      },
      // {
      //   path: "/music/datum_list",
      //   name: "datum_list",
      //   hidden:true,
      //   component: () => import("@/views/music/datum_list"),
      //   meta: { title: "资料库管理", permissions: ["admin"] },
      // },
      // {
      //   path: "/music/datum_edit",
      //   name: "datum_edit",
      //   hidden:true,
      //   component: () => import("@/views/music/datum_edit"),
      //   meta: { title: "资料库编辑", permissions: ["admin"] },
      // },
    ],
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "product",
    meta: { title: "产品管理", permissions: ["admin", "product_type", "product_list"], icon: "tree" },
    children: [
      {
        path: "/product/list",
        name: "list",
        component: () => import("@/views/product/list"),
        meta: { title: "产品列表", permissions: ["admin", "product_list"] },
      },
      {
        path: "/product/list_edit",
        name: "list_edit",
        hidden: true,
        component: () => import("@/views/product/list_edit"),
        meta: { title: "产品编辑", permissions: ["admin", "product_list"] },
      },
      {
        path: "/product/participation_pt",
        name: "participation_pt",
        hidden: true,
        component: () => import("@/views/product/participation_pt"),
        meta: { title: "拼团管理", permissions: ["admin", "product_list"] },
      },
      {
        path: "/product/participation_kj",
        name: "participation_kj",
        hidden: true,
        component: () => import("@/views/product/participation_kj"),
        meta: { title: "砍价管理", permissions: ["admin", "product_list"] },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "order",
    meta: { title: "订单管理", permissions: ["admin", "order_ticket", "order_product"], icon: "form" },
    children: [
      {
        path: "/order/order_product",
        name: "order_product",
        component: () => import("@/views/order/order_product"),
        meta: { title: "产品订单", permissions: ["admin", "order_product"] },
      },
      {
        path: "/order/product_details",
        name: "product_details",
        hidden: true,
        component: () => import("@/views/order/product_details"),
        meta: { title: "产品订单详情", permissions: ["admin", "order_product"] },
      },
      {
        path: "/order/details",
        name: "details",
        hidden: true,
        component: () => import("@/views/order/details"),
        meta: { title: "产品详情", permissions: ["admin", "order_product"] },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "finance",
    meta: { title: "财务管理", permissions: ["admin", "finance_log"], icon: "example" },
    children: [
      {
        path: "/finance/userAccountLog",
        name: "userAccountLog",
        component: () => import("@/views/finance/userAccountLog"),
        meta: { title: "财务流水", permissions: ["admin", "finance_log"] },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "website",
    meta: { title: "建站管理", permissions: ["admin", "banner_index", "park_detail", "banner_ticket", "project_admin", "activity", "about_us", "feedback", "reason", "system"], icon: "system" },
    children: [
      // {
      //   path: "/website/banner_index",
      //   name: "banner_index",
      //   component: () => import("@/views/website/banner_index"),
      //   meta: { title: "首页轮播图", permissions: ["admin", "banner_index"] },
      // },
      {
        path: "/website/VIP_set",
        name: "VIP_set",
        component: () => import("@/views/website/VIP_set"),
        meta: { title: "会员设置", permissions: ["admin", "banner_ticket"] },
      },
      {
        path: "/website/relation",
        name: "relation",
        component: () => import("@/views/website/relation"),
        meta: { title: "联系我们", permissions: ["admin", "system"] },
      },
      {
        path: "/website/about_us",
        name: "about_us",
        component: () => import("@/views/website/about_us"),
        meta: { title: "关于我们", permissions: ["admin", "about_us"] },
      },
      {
        path: "/website/user_service_agreement",
        name: "user_service_agreement",
        component: () => import("@/views/website/about_us"),
        meta: { title: "用户服务协议", permissions: ["admin", "project_admin"] },
      },
      {
        path: "/website/user_privacy_agreement",
        name: "user_privacy_agreement",
        component: () => import("@/views/website/about_us"),
        meta: { title: "用户隐私协议", permissions: ["admin", "project_admin"] },
      },
      {
        path: "/website/leave_word",
        name: "leave_word",
        component: () => import("@/views/website/leave_word"),
        meta: { title: "用户留言", permissions: ["admin", "project_admin"] },
      },
      {
        path: "/website/LookChat",
        name: "LookChat",
        hidden: true,
        component: () => import("@/views/website/LookChat"),
        meta: { title: "查看留言", permissions: ["admin", "project_admin"] },
      },

      {
        path: "/website/setting",
        name: "setting",
        component: () => import("@/views/website/setting"),
        meta: { title: "审核状态" },
      },
      
    ],
  },

  // {
  //   path: "",
  //   component: Layout,
  //   alwaysShow: true,
  //   name: "system",
  //   meta: { title: "系统管理", permissions: ["admin", "role"], icon: "system" },
  //   children: [
  //     // {
  //     //   path: "/roles",
  //     //   name: "role",
  //     //   component: () => import("@/views/role/index"),
  //     //   meta: { title: "角色列表", permissions: ["role"] }
  //     // },
  //     {
  //       path: "/admin/list",
  //       name: "admin",
  //       component: () => import("@/views/admin/index"),
  //       meta: { title: "管理员列表", permissions: ["admin"] }
  //     }
  //   ]
  // },

  { path: "*", redirect: "/error/404", hidden: true },
];
